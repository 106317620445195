<template>
    <v-container fluid class="mx-0 px-3">
        <h1>Logout</h1>
    </v-container>
</template>

<script>
export default {
    methods: {
        logout() {
            this.$store.dispatch("authsMgmt/logout");
        },
    },
    name: "Logout",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.logout();
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
